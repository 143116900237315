* {
  margin: 0;
  padding: 0;
}

.card,
.card-header {
  background-color: #f94f0f !important;
  border: none !important;
  padding: 0 !important;
}
.card {
  margin-bottom: 20px;
}
.accordian {
  margin-bottom: 20px;
  box-shadow: 0 2px 25px 0 rgba(110, 130, 208, 0.18);
}
.accordian-heading {
  background-color: #f94f0f;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  text-decoration: none !important;
  font-size: 1rem;
  cursor: pointer;
}
.accordian-heading div {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.accordian-heading p {
  position: relative;
  font-size: 1rem;
  color: #fff;
  text-align: left;
  justify-items: center;
  margin-bottom: 0px;
  line-height: 2rem;
}
.accordian-heading .accordian-icon {
  background: white;
  color: black;
  height: 2rem;
  width: 2rem;
  text-align: center;
  font-size: 25px;
  margin-bottom: 0;
  border-radius: 5px;
}
@media only screen and (max-width: 991px) {
  .accordian-heading p:nth-child(1) {
    line-height: 1.2rem;
  }
}
.card {
  box-shadow: 0 2px 25px 0 rgba(110, 130, 208, 0.18);
  border-radius: 0.25rem;
}
.accordian-body {
  background-color: #fff !important;
  max-height: 0;
  overflow: hidden;
  padding: 0rem 1rem 0 1rem;
  transition: all 0.7s ease-out;
}

.accordian-body.show {
  height: auto;
  max-height: 999px;
  padding: 1rem;
  transition: all 0.6s ease-in;
}
.card-body {
  background-color: #fff !important;
  border-radius: 0.25rem;
}
.dropi {
  width: 35px;
  height: 35px;
  background: #f94f0f;
  transform: rotate(45deg);
  margin-top: -40px;
  margin-bottom: 10px;
}

/* Image Gallery */

.places-img-container {
  display: grid;
  grid-auto-flow: dense;
  grid-template: "1fr 1fr 1fr";
  justify-content: space-around;
  align-items: center;
}

.places-img-container img {
  width: 200px;
  height: 200px;
  margin: 5px;
  border-radius: 5px;
  transition: all 1s;
}
.places-img-container img:hover {
  transform: scale(1.05);
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .places-img-container {
    grid-template:
      "1fr 1fr 1fr" 250px
      "1fr 1fr 1fr" 250px
      "1fr 1fr 1fr" 250px
      "1fr 1fr 1fr" 250px
      "1fr 1fr 1fr" 250px;
  }
}
@media (max-width: 768px) {
  .places-img-container {
    grid-template: "1fr 1fr";
    justify-content: space-around;
    align-items: center;
  }

  .places-img-container img {
    width: 180px;
    height: 180px;
  }
}

/* .card .btn-link {
  width: 100%;
  height: 60px;
  color: #fff;
  text-align: left;
  text-decoration: none !important;
  padding-right: 50px;
}

.btn-link:hover {
  color: #f94f0f !important;
}

.btn-link::after {
  content: "\2212";
  width: 35px;
  font-size: 25px;
  text-align: center;
  border-radius: 5px;
  right: 15px;
  top: 11px;
  position: absolute;
  color: #000 !important;
  background-color: #fff;
}

.btn-link.collapsed::after {
  content: "\002B";
} */

/* .btn-link::before {
  content: "";
  height: 25px;
  width: 25px;
  position: absolute;
  background: #f94f0f;
  z-index: 1;
  transform: rotate(45deg);
  left: 30px;
  top: 46px;
} */

@media (max-width: 768px) {
  .btn-link::before {
    z-index: -1;
  }
}

/* Indian CSS */

#indian {
  margin-top: 8%;
}

#indian .title {
  text-align: center;
}

#indian .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#indian .title .title-primary:before {
  content: "India";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#indian .title .title-secondary {
  font-weight: 700;
  color: #000;
}

/* image gallery */
.App {
  font-family: sans-serif;
  text-align: center;
}

.imageViewerHeaderButton {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 5px;
}

.imageViewerFooter {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.desktop__only__button {
  display: none;
}

@media (min-width: 1200px) {
  .imageViewerFooter {
    left: 50% !important;
    margin-left: -300px;
    width: 600px !important;
  }

  .desktop__only__button {
    display: unset;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ImageStyleCss {
    left: 50% !important;
    margin-left: -300px;
    width: 600px !important;
  }
}
