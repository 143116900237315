/* CSS Code */

/* Variables */
:root {
  --color-primary: #ff6819;
  --color-secondary: #282828 !important;
  --color-white: #fff;
  --font: "Poppins", sans-serif;
  --font-second: "Satisfy", cursive;
}

/* Custom Classes */
.bg-primary {
  background-color: var(--color-primary);
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

body {
  font-family: var(--font);
}

.top-header .top-nav {
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.top-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.top-nav ul li {
  margin: 0 10px;
}

.top-nav ul li i.fas {
  margin-right: 5px;
}

.top-nav ul li a {
  color: var(--color-white);
}

/* Navbar */

.navbar {
  box-shadow: none;
  background-color: #fff;
}

.navbar .navbar-brand {
  font-weight: 700;
  color: #000;
}

.navbar li.nav-link {
  margin: 0px 2px !important;
}

.navbar a.nav-link {
  color: #000;
  border-bottom: 2px solid #fff;
  transition: 0.5s ease;
  /* padding: 10px 20px !important; */
}

.navbar a.nav-link:hover {
  color: #000;
  border-bottom-color: var(--color-primary);
}
.nav-check {
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
}

header {
  position: sticky;
  top: 0;
  z-index: 9999;
  box-shadow: 0px 4px 20px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
}

@media only screen and (max-width: 991px) {
  .nav-check {
    display: block;
    max-height: 0 !important;
    overflow: hidden !important;
    transition: max-height 0.5s linear !important;
  }

  .nav-check.shown {
    height: auto !important;
    max-height: 600px !important;
    transition: max-height 1s linear !important;
  }
}

/* Banner CSS */

#banner {
  width: 100%;
  position: relative;
}

#banner .swiper-container {
  width: 100%;
  height: 95vh;
}

#banner .swiper-container .swiper-slide-b {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

#banner .swiper-container .swiper-slide .caption {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  padding: 0 6em;
  color: #fff;
  z-index: 40;
}

#banner .swiper-container .swiper-slide .caption h2 {
  color: var(--color-primary);
  font-family: var(--font-second);
}

#banner .swiper-container .swiper-slide .caption h1 {
  font-size: 4rem;
  width: 50%;
  font-weight: 900;
  text-transform: capitalize;
}

#banner .swiper-container .swiper-slide .caption a {
  color: var(--color-primary);
  font-weight: 600;
}

#banner .swiper-container .swiper-slide .caption a i.fas {
  width: 40px;
  height: 40px;
  background-color: var(--color-white);
  text-align: center;
  line-height: 40px;
  border-radius: 100%;
  margin-right: 15px;
  animation: animation-play-btn 1s infinite alternate;
  cursor: pointer;
}

@keyframes animation-play-btn {
  from {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.4);
  }
  to {
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.4);
  }
}

section {
  scroll-margin-top: 150px; /* Adjust this value according to the height of your navbar */
}

/* Featured Section */

#featured {
  width: 100%;
  padding: 50px 0;
  margin-top: 25px;
}

#featured .title {
  text-align: center;
}

#featured .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#featured .title .title-primary:before {
  content: "featured";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#featured .title .title-secondary {
  font-weight: 700;
  color: #000;
}
#featured .img-fluid {
  width: 100%;
}

/* Showcase CSS */
#showcase {
  width: 100%;
  padding: 50px 0;
  background-color: #eee;
  position: relative;
  z-index: -1;
}

#showcase:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: url("https://images.unsplash.com/photo-1455620611406-966ca6889d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=830&q=80");
  background-size: cover;
  z-index: -1;
}

#showcase .title {
  z-index: 4;
  text-align: center;
}

#showcase .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}
#showcase .title .title-primary:before {
  content: "Showcase";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}
#showcase .title .title-secondary {
  font-weight: 700;
  color: #000;
}

#showcase .swiper-container {
  width: 100%;
  height: 400px;
  z-index: 99;
  /* display: none; */
}

#showcase .swiper-container .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#showcase .services {
  width: 100%;
  text-align: center;
  margin: 60px 0;
}

#showcase .services .card {
  padding: 30px 20px !important;
  background-color: #fff !important;
}

#showcase .services .card i.fas {
  color: var(--color-primary);
  font-size: 56px;
  margin-bottom: 20px;
}

#showcase .services .card h4 {
  font-weight: bold;
}

#showcase .services .card p {
  font-size: 0.9rem;
  opacity: 0.9;
  font-weight: 400;
  margin: 20px 0;
}

#showcase .services .card a {
  color: #000;
  padding: 10px;
  font-weight: 700;
}

/* Listing CSS */
#listing {
  width: 100%;
  padding: 100px;
}

#listing .title {
  text-align: center;
}

#listing .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}
#listing .title .title-primary:before {
  content: "Listing";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}
#listing .title .title-secondary {
  font-weight: 700;
  color: #000;
}

#listing .swiper-container {
  width: 100%;
  height: 400px;
  margin: 60px 0;
  border: 1px solid #000;
}

#listing .swiper-container .swiper-slide {
  width: 100%;
}

#listing .swiper-container .swiper-slide .image {
  width: 100%;
  height: 200px;
  position: relative;
  z-index: -1;
}

#listing .swiper-container .swiper-slide .image img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

#listing .swiper-container .swiper-slide .listing-count {
  width: 90%;
  margin: 0 auto;
  margin-top: -30px;
  padding: 10px 12px;
  font-size: 0.8em;
  background-color: var(--color-white) !important;
  z-index: 99;
  display: flex;
  justify-content: space-between;
}

#listing .swiper-container .swiper-slide .listing-count .item i.fas {
  color: var(--color-primary);
  margin-right: 5px;
}

/* Transport CSS */

#transport {
  margin-top: 8%;
}

#transport .title {
  text-align: center;
}

#transport .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#transport .title .title-primary:before {
  content: "Vehicles";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#transport .title .title-secondary {
  font-weight: 700;
  color: #000;
}

/* International CSS */

#international {
  margin-top: 8%;
}

#international .title {
  text-align: center;
}

#international .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#international .title .title-primary:before {
  content: "International";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#international .title .title-secondary {
  font-weight: 700;
  color: #000;
}

/* Reasons CSS */

#reasons {
  margin-top: 8%;
}

#reasons .title {
  text-align: center;
}

#reasons .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#reasons .title .title-primary:before {
  content: "Satisfaction";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#reasons .title .title-secondary {
  font-weight: 700;
  color: #000;
}

/* testimonial-section */
#Testimonial {
  width: 100%;
  padding: 50px 0;
  margin-top: 25px;
}

#Testimonial .title {
  text-align: center;
}

#Testimonial .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#Testimonial .title .title-primary:before {
  content: "Testimonial";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#Testimonial .title .title-secondary {
  font-weight: 700;
  color: #000;
}
#Testimonial .title {
  margin-bottom: 3rem;
}
.testimonial-container {
  width: 1400px;
}
.testimonial-section .testimonial-card {
  box-shadow: 0px 12px 34px rgb(110 110 110 / 15%);
  /* background-color: #f8f8f8; */
  border-radius: 10px;
  padding: 10px;
}
.owl-carousel .owl-stage-outer {
  padding: 40px 10px;
  /* height: 380px */
}
.active-star {
  color: #fbc02d;
  margin-bottom: 8px;
}

.active-star:hover {
  color: #f9a825;
  cursor: pointer;
}
.testimonial-section img {
  width: 45px !important;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.testimonial-section .name {
  padding-left: 10px;
  font-weight: 700;
  color: var(--color-primary);
}
.owl-nav {
  display: none !important;
}
.dFnojX {
  height: 15rem;
}
.Main__Container-sc-1n4ud0o-0 > a {
  display: none !important;
}

/* About CSS */

#about {
  margin-top: 8%;
}

#about .title {
  text-align: center;
}

#about .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#about .title .title-primary:before {
  content: "About Us";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#about .title .title-secondary {
  font-weight: 700;
  color: #000;
}

/* Food CSS */

#food {
  margin-top: 8%;
}

#food .title {
  text-align: center;
}

#food .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#food .title .title-primary:before {
  content: "Food Package";
  position: absolute;
  top: 100%;
  left: 50%;
  right: -10%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#food .title .title-secondary {
  font-weight: 700;
  color: #000;
}

/* Back to top CSS */

/* Responsive CSS */

@media only screen and (max-width: 991px) {
  .top-header {
    display: none;
  }

  #banner .swiper-container {
    width: 100%;
    height: 60vh;
  }

  #banner .swiper-container .swiper-slide .caption h1 {
    font-size: 2rem;
    width: 100%;
  }

  #banner .swiper-container .swiper-slide .caption .container {
    margin-left: -55px;
  }

  #showcase {
    padding: 10px !important;
  }

  #showcase:before {
    height: 200px;
  }

  #showcase .swiper-container {
    height: 150px;
    margin-top: -25px;
  }

  #featured .title .title-primary:before {
    /* content: ""; */
    font-size: 60px;
  }

  #showcase .title .title-primary:before {
    /* content: ""; */
    font-size: 60px;
  }

  #transport .title .title-primary:before {
    /* content: ""; */
    font-size: 60px;
  }

  #international .title .title-primary:before {
    /* content: ""; */
    font-size: 50px;
  }
  #food .title .title-primary:before {
    /* content: ""; */
    font-size: 50px;
  }
  #reasons .title .title-primary:before {
    /* content: ""; */
    font-size: 50px;
  }
  #Testimonial .title .title-primary:before {
    /* content: ""; */
    font-size: 50px;
  }
  #about .title .title-primary:before {
    /* content: ""; */
    font-size: 50px;
  }
}

/* .ContactSnackBar {
  display: none;
} */

@media only screen and (max-width: 600px) {
  /* .ContactSnackBar {
    display: block;
    bottom: 10px;
    top: auto;
    color: #fff;
    height: auto;
    margin-bottom: -10px;
    position: fixed;
    width: 100%;
    z-index: 9;
    -webkit-transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    transition: all ease-in-out 0.5s;
    z-index: 999;
  } */

  /* .ContactSnackBar > ul {
    padding-left: 0;
  }

  .ContactSnackBar > ul > li {
    background-color: #f94f0f;
    border-right: 1px solid #fff;
    float: left;
    width: 50%;
    list-style: none;
    text-align: center;
  }

  .ContactSnackBar > ul > li > a {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 12px;
    text-decoration: none;
    padding: 12px;
  } */
}
@media (min-width: 1281px) {
  .ContactSnackBar {
    opacity: 1;
    margin-bottom: 0;
    position: fixed;
    right: 0;
    top: 50%;
    bottom: auto;
    width: 140px;
    height: auto;
    text-align: left;
  }

  .ContactSnackBar.detail > ul > li {
    transform: rotate(-90deg);
    width: 100%;
    margin-left: 35px;
    transition: margin-left ease-in-out 0.3s;
    list-style: none;
  }

  .ContactSnackBar > ul > li {
    border-right: none;
    float: none;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 60px;
    transition: margin-left ease-in-out 0.3s;
  }

  .call-ico {
    display: none;
  }

  .ContactSnackBar > ul > li:last-child {
    border-right: none;
  }

  .ContactSnackBar.detail > ul > li > a {
    padding: 12px;
  }

  .ContactSnackBar > ul > li > a {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 15px;
    text-decoration: none;
    padding: 12px;
    width: 150px;
    background: #f94f0f;
  }

  .ContactSnackBar.detail > ul > li > a > span {
    display: inline-block;
    margin: 0;
  }
}

@media only screen and (max-width: 1281px) {
  .ContactSnackBar {
    display: block;
    bottom: 10px;
    top: auto;
    color: #fff;
    height: auto;
    margin-bottom: -10px;
    position: fixed;
    width: 100%;
    z-index: 9;
    transition: all ease-in-out 0.5s;
    z-index: 9999999;
  }

  .ContactSnackBar > ul {
    padding-left: 0;
  }

  .ContactSnackBar > ul > li {
    background-color: #f94f0f;
    border-right: 1px solid #fff;
    float: left;
    width: 50%;
    list-style: none;
    text-align: center;
  }

  .ContactSnackBar > ul > li > a {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 12px;
    text-decoration: none;
    padding: 12px;
  }
}
/* Contact Us CSS */

.form-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #ff6819;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #fa0000);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #ff6819;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fff;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.contact-btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fff;
  font-size: 0.95rem;
  color: #ff6819;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.contact-btn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #ff6819;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #ff6819;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #fa0000, #ff6819);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #ff6819;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #fa0000, #ff6819);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fff;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.big-circle2 {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #fa0000, #ff6819);
  /* top: 50%; */
  left: 70%;
  transform: translate(-40%, 38%);
}

.big-circle2:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fff;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

@media only screen and (max-width: 991px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}
