/* Cars Section CSS */

#cars {
    margin-top: 8%;
  }
  
  #cars .title {
    text-align: center;
  }
  
  #cars .title .title-primary {
    color: var(--color-primary);
    font-family: var(--font-second);
    position: relative;
  }
  
  #cars .title .title-primary:before {
    content: "Cars";
    position: absolute;
    top: 100%;
    left: 50%;
    right: -10%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    color: #000;
    opacity: 0.2;
    z-index: -1;
  }
  
  #cars .title .title-secondary {
    font-weight: 700;
    color: #000;
  }

  /* Mini Coach Section CSS */

#miniCoach {
  margin-top: 8%;
}

#miniCoach .title {
  text-align: center;
}

#miniCoach .title .title-primary {
  color: var(--color-primary);
  font-family: var(--font-second);
  position: relative;
}

#miniCoach .title .title-primary:before {
  content: "AC & Non AC";
  position: absolute;
  top: 100%;
  left: 50%;
  right: -10%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  color: #000;
  opacity: 0.2;
  z-index: -1;
}

#miniCoach .title .title-secondary {
  font-weight: 700;
  color: #000;
}

@media only screen and (max-width: 991px){
  #miniCoach .title .title-primary::before{
    font-size: 50px;
    line-height: 3rem;
  }
}